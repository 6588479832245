import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import { useTheme} from '@mui/material/styles';
import { Button, Tooltip, Typography } from '@mui/material';
import { pages, page_tooltips } from './Config';

//Left Navbar
function Navbar(props) {
    const theme = useTheme();

    return (
        <Box sx={{ p: 0, width: "10vh", 
                    height: "100vh",
                    display: "flex", 
                    flexDirection: "column",
                    alignItems: "center", 
                    justifyContent: "space-between",
                    backgroundColor: theme.palette.primary.main }}>
            
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%"}}>
                <Typography variant="h4" sx={{color: "white", margin: "10px", textAlign: "center"}}>Lyla Loom</Typography>
                {pages.map((page) => {
                    if (page != "Account") {
                        return (
                            <Tooltip title={page_tooltips[page]} key={page}>
                                <Box sx={{m: 2, width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                }} key={page}>
                                    <Link to={page.toLowerCase()} style={{textDecoration: "none", color: "white", fontSize: "20px", margin: "5px", padding: "5px", border: "1px solid white", borderRadius: "5px"}} key={page}>{page}</Link>
                                </Box>
                            </Tooltip>
                        )
                    }
                })}
            </Box>
            <Box sx={{m :2}}>
                <Link to={"/account"} style={{textDecoration: "underline", color: "white", fontSize: "20px", margin: "10px"}} key={"Account"}>Account</Link>
            </Box>
        </Box>
    );
}

export default Navbar;