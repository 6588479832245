import React, {useEffect, useState} from 'react';
import { code_size, categories, outfit_combinations, max_articles, tops, bottoms, affiliates } from './Config';
import { getFirestore, doc, setDoc, getDoc, getDocs, collection, query, where, orderBy, limit } from "firebase/firestore";
import { Box } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Tooltip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


function RecommendationPage(props) {
    const [closetItems, setClosetItems] = useState({});
    const [affiliateItems, setAffiliateItems] = useState({});
    const [allItems, setAllItems] = useState([]);
    const [outfits, setOutfits] = useState([]);
    const [affiliateOutfit, setAffiliateOutfit] = useState([]);
    const [curOutfit, setCurOutfit] = useState(0);
    const [generating, setGenerating] = useState(true);

    useEffect(() => {
        if(props.user === null) {
            return;
        }

        getClosetItems().then((closet) => {
            setAllItems(closet);
            let categoryCloset = {}
            for (let i = 0; i < closet.length; i++) {
                if (closet[i].category in categoryCloset) {
                    categoryCloset[closet[i].category].push(closet[i]);
                } else {
                    categoryCloset[closet[i].category] = [closet[i]];
                }
            }

            //Iterate over keys
            for (let key in categoryCloset) {
                let category = categoryCloset[key];
                for (let i = 0; i < category.length; i++) {
                    categoryCloset[key][i].similar = 
                        getSimilarArticle(category[i].embedding, 
                                        category, 
                                        5, 
                                        [category[i].id]); 
                }
            }
            if (Object.keys(categoryCloset).length === 0) {
                setGenerating(false);
                return;
            }
            setClosetItems(categoryCloset);
        });

        getAffiliateItems().then((affiliate) => {
            setAffiliateItems(affiliate);
        });
    }, [props.user])

    useEffect(() =>{
        setOutfits([]);

        if (Object.keys(affiliateItems).length === 0) {
            return;
        }
        if (Object.keys(closetItems).length === 0) {
            return;
        }


        if (outfits.length == 0)
        {
            for (let i = 0;i < 3;i++)
            {     
                generateOutfit().then((outfit) => {
                    if (outfit == null) {
                        setGenerating(false);
                        return;
                    }
                    setOutfits((prev) => [...prev, outfit]);
                });
            }
        }
        generateAffiliateOutfit().then((outfit) => {
            if (outfit == null) {
                setGenerating(false);
                return;
            }
            setAffiliateOutfit(outfit);
        });

    }, [affiliateItems, closetItems])

    const getClosetItems = async () => {
        if (props.user === null) {
            return [];
        }
        let closetItems = props.user.closet;

        //Get items from articles db
        const db = getFirestore();
        let closetItemsData = [];
        
        //Start requests for all articles and await them
        let promises = [];
        for (let i = 0; i < closetItems.length; i++) {
            promises.push(getDoc(doc(db, "articles", closetItems[i])));
        }
        let results = await Promise.all(promises);

        //Get data from results
        for (let i = 0; i < results.length; i++) {
            let data = results[i].data();
            data.id = results[i].id;
            closetItemsData.push(data);
        }
        return closetItemsData;
    }

    const getAffiliateItems = async () => {
        //Get all articles with origin = "newchic"
        const db = getFirestore();
        const q = query(collection(db, "articles"), where("origin", "==", "newchic"));
        const querySnapshot = await getDocs(q);
        let items = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            items.push(data);
        });

        //Filter by category
        let filteredItems = {};
        for (let i = 0; i < items.length; i++) {
            if (items[i].category in filteredItems) {
                filteredItems[items[i].category].push(items[i]);
            } else {
                if (parseInt(items[i].category) <= 11)
                {
                    filteredItems[items[i].category] = [items[i]];
                }
            }
        }

        //Get similar articles
        for (let key in filteredItems) {
            for (let i = 0; i < filteredItems[key].length; i++) {
                filteredItems[key][i].similar = 
                    getSimilarArticle(filteredItems[key][i].embedding, 
                                    filteredItems[key], 
                                    5, 
                                    [filteredItems[key][i].id]); 
            }
        }

        return filteredItems;
    }

    const getSimilarArticle = (embedding, searchSpace, topn, blacklist = []) => {
        //Sort by cosine similarity to embedding and return topn
        let similarities = [];
        for (let i = 0; i < searchSpace.length; i++) {
            if (!blacklist.includes(searchSpace[i].id)) {
                similarities.push({
                    id: searchSpace[i].id,
                    similarity: cosineSimilarity(embedding, searchSpace[i].embedding)
                });
            }
        }

        similarities.sort((a, b) => b.similarity - a.similarity);
        return similarities.slice(0, topn);
    }

    const cosineSimilarity = (a, b) => {
        let dotProduct = 0;
        let aMagnitude = 0;
        let bMagnitude = 0;
        for (let i = 0; i < a.length; i++) {
            dotProduct += a[i] * b[i];
            aMagnitude += a[i] * a[i];
            bMagnitude += b[i] * b[i];
        }
        aMagnitude = Math.sqrt(aMagnitude);
        bMagnitude = Math.sqrt(bMagnitude);
        return dotProduct / (aMagnitude * bMagnitude);
    }

    const getOutfitPrediction = async (articles) => {
        let embeddings = getOutfitEmbedding(articles, props.user.avgLikedOutfit);

        const input = {
            outfit: embeddings
        }

        const url = "https://us-central1-lylaloom.cloudfunctions.net/fashion_prediction"
        const result = await fetch(url, {
            method: "POST",
            headers: {                
                "Content-Type": "application/json",
            },
            body: JSON.stringify(input)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            return data;
        }).catch((error) => {
            console.log(error);
        });

        return result;
    }

    const getOutfitEmbedding = (articles, user_embedding) => {
        if (user_embedding.length > code_size) {
            user_embedding = user_embedding.slice(0, code_size);
        }
        let embedding = user_embedding;
        for (let i = 0; i < articles.length; i++) {
            embedding = embedding.concat(articles[i].embedding);
        }

        //Pad with zeros if there are less than 5 articles
        if (embedding.length < (max_articles)*code_size) {
            let padLength = ((max_articles) - (embedding.length/code_size)) * code_size;
            for (let i = 0; i < padLength; i++) {
                embedding.push(0);
            }
        }
        return embedding;
    }

    const randomOutfit = (categories) => {
        let outfit = [];
        for (let i = 0; i < categories.length; i++) {
            let category = categories[i];
            let randomIndex = Math.floor(Math.random() * closetItems[category].length);
            outfit.push(closetItems[category][randomIndex]);
        }
        return outfit;
    }

    const categoryIndex = (category) => {
        return categories.indexOf(category);
    }

    const randomValidClosetOutfit = () => {
        let valid_list = [];
        for (let i = 0; i < outfit_combinations.length; i++) {
            //If the user has at least one item in each category
            if (outfit_combinations[i].every((category) => {
                return category in closetItems && closetItems[category].length > 0;
            })) {
                valid_list.push(outfit_combinations[i]);
            }
        }

        let randomIndex = Math.floor(Math.random() * valid_list.length);
        return valid_list[randomIndex];
    }

    const generateOutfit = async (iterations, temperatue, earlyTerminateThreshold) => {
        let validOutfit = randomValidClosetOutfit();
        if (validOutfit == null) {
            return null;
        }
        let outfit = randomOutfit(validOutfit);
        let curScore = await getOutfitPrediction(outfit);
        //Simulated annealing
        for (let i = 0;i<iterations;i++) {
            let outfitCopy = outfit.slice();

            //Pick random article to replace
            let randomIndex = Math.floor(Math.random() * outfitCopy.length);
            
            //Pick random article from similar list to replace with
            let similarArticles = closetItems[validOutfit[randomIndex]][randomIndex].similar;
            let randomSimilarIndex = Math.floor(Math.random() * similarArticles.length);
            let randomSimilarArticleID = similarArticles[randomSimilarIndex];

            let randomSimilarArticle = allItems.find((article) => article.id == randomSimilarArticleID.id);
            //Replace article
            outfitCopy[randomIndex] = randomSimilarArticle;

            //Get outfit prediction
            let outfitPrediction = await getOutfitPrediction(outfitCopy);
            if (outfitPrediction.error) {
                console.log(outfitPrediction.error);
                return outfit;
            }
            let outfitPredictionScore = outfitPrediction.predictions[0][0];

            //Calculate probability of accepting worse solution
            let probAccept = Math.exp((outfitPredictionScore - curScore) / temperatue);

            //Accept worse solution with probability probAccept
            if (outfitPredictionScore > curScore || Math.random() < probAccept) {
                outfit = outfitCopy;
                curScore = outfitPredictionScore;
                
            } else if (outfitPredictionScore > earlyTerminateThreshold) {
                outfit = outfitCopy;
                break;
            }
        }
        return outfit;
    }

    const randomValidOutfit = () => {
        let valid_list = [];
        for (let i = 0; i < outfit_combinations.length; i++) {
            //If the user has at least one item in each category except one
            let valid = true;
            for (let j = 0; j < outfit_combinations[i].length; j++) {
                if (!(outfit_combinations[i][j] in closetItems && closetItems[outfit_combinations[i][j]].length > 0)) {
                    valid = false;
                    break;
                }
            }
            if (valid) {
                valid_list.push(outfit_combinations[i]);
            }
        }

        let randomIndex = Math.floor(Math.random() * valid_list.length);
        return valid_list[randomIndex];
    }

    //Generate an outfit, while guarenteeing that at least one article is from the affiliate store
    const generateAffiliateOutfit = async (iterations, temperatue, earlyTerminateThreshold) => {
        const randomAffiliateOutfit = (categories) => {

            let affiliateIndex = Math.floor(Math.random() * categories.length);
            //If the user doesn't have clothes in a given cateogry then the affiliate index has to be that one
            for (let i = 0; i < categories.length; i++) {
                if (!(categories[i] in closetItems && closetItems[categories[i]].length > 0)) {
                    affiliateIndex = i;
                    break;
                }
            }
            let outfit = [];
            for (let i = 0; i < categories.length; i++) {
                let cat_index = categoryIndex(categories[i]);
                if (i == affiliateIndex) {
                    let randomIndex = Math.floor(Math.random() * affiliateItems[cat_index].length);
                    outfit.push(affiliateItems[cat_index][randomIndex]);
                } else {
                    let randomIndex = Math.floor(Math.random() * closetItems[categories[i]].length);
                    outfit.push(closetItems[categories[i]][randomIndex]);
                }
            }
            
            return [outfit, affiliateIndex];
        }

        let validOutfit = randomValidOutfit();
        if (validOutfit == null) {
            return null;
        }
        let result = randomAffiliateOutfit(validOutfit);
        let affiliate_outfit = result[0];
        let affiliateIndex = result[1];

        let curScore = await getOutfitPrediction(affiliate_outfit);
        //Simulated annealing
        for (let i = 0;i<iterations;i++) {
            let outfitCopy = affiliate_outfit.slice();

            //Pick random article to replace
            let randomIndex = Math.floor(Math.random() * outfitCopy.length);

            let similarArticles;
            if (randomIndex == affiliateIndex) {
                similarArticles = affiliateItems[validOutfit[randomIndex]][randomIndex].similar;
            }
            else {
                similarArticles = closetItems[validOutfit[randomIndex]][randomIndex].similar;
            }

            //Pick random article from similar list to replace with
            let randomSimilarIndex = Math.floor(Math.random() * similarArticles.length);
            let randomSimilarArticleID = similarArticles[randomSimilarIndex];

            let randomSimilarArticle = allItems.find((article) => article.id == randomSimilarArticleID.id);
            //Replace article
            outfitCopy[randomIndex] = randomSimilarArticle;

            //Get outfit prediction
            let outfitPrediction = await getOutfitPrediction(outfitCopy);
            if (outfitPrediction.error) {
                return outfitCopy;
            }
            let outfitPredictionScore = outfitPrediction.predictions[0][0];

            //Calculate probability of accepting worse solution
            let probAccept = Math.exp((outfitPredictionScore - curScore) / temperatue);

            //Accept worse solution with probability probAccept
            if (outfitPredictionScore > curScore || Math.random() < probAccept) {
                affiliate_outfit = outfitCopy;
                curScore = outfitPredictionScore;   
            } else if (outfitPredictionScore > earlyTerminateThreshold) {
                affiliate_outfit = outfitCopy;
                break;
            }
        }
        return affiliate_outfit;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
        }}>
            {outfits.length == 0 && <>{generating ? <>
                <Typography variant="h4">Generating outfits...</Typography>
                <CircularProgress/>
            </> : 
                <Typography variant="h4">No outfits found</Typography>
            }</>}

                {outfits.map((cOutfit, index) => {
                return (
                <Accordion key={index}>
                    <AccordionSummary key={index} expandIcon={<ExpandMoreIcon />}>
                        <Typography>Casual</Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}>
                        {cOutfit.map((article) => {
                            return (<img src={article.photoURL} style={{height: "50px"}}/>)
                        })}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{width: {xs: "100%", sm: "400px", md: "600px", lg: "700px"},
                            display: "flex", flexDirection: "column", mb: 2}}>
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", 
                                justifyContent: "center"}}>
                            {cOutfit.map((article) => {
                                let cat_name = article.category;
                                //Check if is a number
                                if (cat_name.match(/\d+/g)) {
                                    cat_name = categories[parseInt(cat_name)];
                                }
                                if (tops.includes(cat_name))
                                {
                                    return (<Box key={article.id} sx={{
                                        cursor: "pointer",
                                    }}
                                    component={Link} 
                                    to={`/details/${article.id}`}
                                    > 
                                        <Tooltip title={cat_name}>
                                            <img src={article.photoURL} style={{height: "300px"}}/>
                                        </Tooltip>
                                    </Box>)
                                }
                            })}
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center",
                                justifyContent: "center", mt: 1}}>
                            {cOutfit.map((article) => {
                                let cat_name = article.category;
                                //Check if is a number
                                if (cat_name.match(/\d+/g)) {
                                    cat_name = categories[parseInt(cat_name)];
                                }
                                if (bottoms.includes(cat_name))
                                {
                                    return (<Box key={article.id} sx={{
                                        cursor: "pointer",
                                    }}
                                    component={Link} 
                                    to={`/details/${article.id}`}
                                    > 
                                        <Tooltip title={cat_name}>
                                            <img src={article.photoURL} style={{height: "300px"}}/>
                                        </Tooltip>
                                    </Box>)
                                }
                            })}
                        </Box>
                    </AccordionDetails>
                </Accordion>)})}
                
                {affiliateOutfit.length > 0 && 
                <Accordion>
                    <AccordionSummary key={"affiliate_accordion"} expandIcon={<ExpandMoreIcon />}>
                        <Typography>Affiliate Outfit</Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}>
                        {affiliateOutfit.map((article) => {
                            return (<img src={article.photoURL} style={{height: "50px"}}/>)
                        })}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{width: {xs: "100%", sm: "400px", md: "600px", lg: "700px"},
                                display: "flex", flexDirection: "column", mb: 2}}>
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", 
                                justifyContent: "center"}}>
                            {affiliateOutfit.map((article) => {
                                let cat_name = article.category;
                                //Check if is a number
                                if (cat_name.match(/\d+/g)) {
                                    cat_name = categories[parseInt(cat_name)];
                                }
                                if (tops.includes(cat_name))
                                {

                                    return (<Box key={article.id} sx={{
                                        cursor: "pointer",
                                    }}
                                    component={Link} 
                                    to={`/details/${article.id}`}
                                    > 
                                        <Tooltip title={cat_name}>
                                            <img src={article.photoURL} style={{height: "300px"}}/>
                                        </Tooltip>
                                    </Box>)
                                }
                            })}
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center",
                                justifyContent: "center", mt: 1}}>
                            {affiliateOutfit.map((article) => {
                                let cat_name = article.category;
                                //Check if is a number
                                if (cat_name.match(/\d+/g)) {
                                    cat_name = categories[parseInt(cat_name)];
                                }
                                if (bottoms.includes(cat_name))
                                {
                                    return (<Box key={article.id} sx={{
                                        cursor: "pointer",
                                    }}
                                    component={Link} 
                                    to={`/details/${article.id}`}
                                    > 
                                        <Tooltip title={cat_name}>
                                            <img src={article.photoURL} style={{height: "300px"}}/>
                                        </Tooltip>
                                    </Box>)
                                }
                            })}
                        </Box>
                    </AccordionDetails>
                </Accordion>}
        </Box>
    )
}

export default RecommendationPage;