import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, addDoc, query, where, doc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import { Box } from "@mui/system";
import { AppBar, Button, Checkbox, CircularProgress, Fab, FormControlLabel, FormGroup, IconButton, InputAdornment, Menu, Paper, TextField, Typography } from "@mui/material";
import { useTheme} from '@mui/material/styles';
import Search from "@mui/icons-material/Search";
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {bottoms, categories, tops} from "./Config";

function ClosetPage(props) {
    const [search, setSearch] = useState("");
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [filters, setfilters] = useState(categories);
    const [closetItems, setClosetItems] = useState([]); //Array of objects with article data
    const [displayedItems, setDisplayedItems] = useState([]); //Array of objects with article data
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    useEffect(() => {
        if(props.user)
        {
            getClosetItems();
        }
    }, [props.user])

    useEffect(() => {
        let filteredClosetItems = closetItems.filter((item) => { 
            return filters.includes(item.category);
        });
        let filteredClosetItems2 = [];
        switch(search.toLowerCase()) {
            case "shirt":
                //Get all shirts tops that aren't outerwear
                filteredClosetItems2 = filteredClosetItems.filter((item) => {
                    return tops.includes(item.category) && !item.category.toLowerCase().includes("outerwear");
                });
                break;
            case "outerwear":
                //Get all outerwear tops
                filteredClosetItems2 = filteredClosetItems.filter((item) => {
                    return tops.includes(item.category) && item.category.toLowerCase().includes("outerwear");
                });
                break;
            case "bottom":
                //Get all bottoms
                filteredClosetItems2 = filteredClosetItems.filter((item) => {
                    return !bottoms.includes(item.category);
                });
                break;
            case "top":
                //Get all tops
                filteredClosetItems2 = filteredClosetItems.filter((item) => {
                    return tops.includes(item.category);
                });
                break;
            default:
                filteredClosetItems2 = filteredClosetItems.filter((item) => {
                    return item.category.toLowerCase().includes(search.toLowerCase());
                });
                break;
        }
        
        setDisplayedItems(filteredClosetItems2);

    }, [filters, search, closetItems])

    const openFilterMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const getClosetItems = async () => {
        let closetItems = props.user.closet;

        //Get items from articles db
        const db = getFirestore();
        let closetItemsData = [];
        
        //Start requests for all articles and await them
        let promises = [];
        for (let i = 0; i < closetItems.length; i++) {
            promises.push(getDoc(doc(db, "articles", closetItems[i])));
        }
        let results = await Promise.all(promises);

        //Get data from results
        for (let i = 0; i < results.length; i++) {
            let data = results[i].data();
            data.id = results[i].id;
            closetItemsData.push(data);
        }
        setLoading(false);
        setClosetItems(closetItemsData);
    }

    return (
        <Box sx={{width: "100%", height: "100%", overflowY: "auto", overflowX: "hidden"}}>
            {/* Top Control Bar */}
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "start", backgroundColor: "white", height: "50px", width: "100%", p: 1, pr: 5 }}>
                {/* Search */}
                
                <TextField 
                value={search} 
                onChange={(e) => {setSearch(e.target.value)}} 
                sx={{minWidth: "300px", height: "100%", backgroundColor: "white"}} 
                placeholder="Search"
                variant="standard"
                />

                {/* Filter */}
                <IconButton
                sx={{}}
                onClick={openFilterMenu}
                >
                    <FilterListIcon/>    
                </IconButton>

                <Menu
                    elevation={1}
                    open={open}
                    onClose={() => {setAnchorEl(null)}}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    anchorEl={anchorEl}
                    
                >
                    <FormGroup
                    sx={{
                        padding: 1
                    }}
                    >
                        {categories.map((category) => {
                            return (
                                <FormControlLabel
                                key={category}
                                control={
                                <Checkbox 
                                    checked={filters.includes(category)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setfilters([...filters, category])
                                        } else {
                                            setfilters(filters.filter((filter) => filter !== category))
                                        }
                                    }}
                                />
                            } label={category} />
                            )
                        })}
                    </FormGroup>
                </Menu>


            </Box>

            {/* Closet Items */}
            {displayedItems.length > 0 ?
            <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry
                gutter="10px"
                >
                    {displayedItems.map((item) => {
                        return (
                            <Button
                            key={item.id}
                            sx={{
                                backgroundImage: `url(${item.photoURL})`,
                                backgroundSize: "auto 100%",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                height: "350px",
                                width: "100%",
                                '&:hover': {
                                    boxShadow: 3,

                                }
                            }}
                                component={Link}
                                to={`/details/${item.id}`}
                            />
                        )
                    })}
                </Masonry>
            </ResponsiveMasonry>
            : 
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
                width: "100%",
                backgroundColor: "white"
            }}>
                {search !== "" ? <Typography variant="h4">No results found</Typography>
                :
                <>
                    {loading ? <CircularProgress/> :
                        <>
                            <Typography variant="h4">Your closet is empty</Typography>
                            <Typography variant="h6">Add some items to get started</Typography>
                        </>
                    }
                </>}
            </Box>
            }

            {/* Add Item FAB*/}
            <Fab color="primary"
            sx={{
                position: "fixed",
                bottom: "20px",
                right: "20px"
            }}
            component={Link}
            to="/add"
            >
                <AddIcon/>
            </Fab>
        </Box>
    );
}

export default ClosetPage;