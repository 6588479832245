import { Box } from '@mui/system';
import React from 'react';

function PrivacyPage(props) {
    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <h1>Privacy Policy</h1>
            <p>Effective date: February 13, 2023</p>
            <p>Lyla Loom ("us", "we", or "our") operates the Lyla Loom website (the "Service").</p>
            <p>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Personal Data</h3>
            <p>We collect identifying personal data for the purpose of applying unique identifiers to each account. These unique identifiers are either email/password pairings or a Google Authentication token generated when a user chooses to sign in with a Google Authentication provider. This data may be used to contact you in order to inform you of updates to this Privacy Policy</p>
            <h3>Usage Data</h3>
            <p>
                We collect usage data for the purpose of personalizing your experience with the 
                Service. This data includes likes and dislikes of outfits, article category identifications, and embeddings generated from uploaded articles. Article embeddings do contain information of the original article image, but this information is not used to identify you.
            </p>
            <p>Additionally articles uploaded to the service may be used to generate new machine learning models to further improve predictions. Articles used for this are scrubbed of personally identifiable information.</p>
            <h2>Deleteing Your Data</h2>
            <p>Usage data collected during use can be deleted by selecting each article in the closet page and selecting the Delete button. Outfit profile information can be deleted by choosing to delete User Outfit Profile in the Account page.</p>

            <h2>Changes to this Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
            </p>

            <h2>Contact Us</h2>
            <p>
                If you have any questions about this Privacy Policy, please contact us at 
                &nbsp;
                <a href="mailto:hunterlivesay@lylaloom.com">
                    hunterlivesay@lylaloom.com
                </a>
            </p>
        </Box>
    );
}

export default PrivacyPage;