const categories = [
    "Short Sleeve Top",
    "Long Sleeve Top",
    "Short Sleeve Outwear",
    "Long Sleeve Outwear",
    "Vest",
    "Sling",
    "Shorts",
    "Trousers",
    "Skirt",
    "Short Sleeve Dress",
    "Long Sleeve Dress",
    "Vest Dress",
    "Sling Dress",
];

const tops = [
    "Short Sleeve Top",
    "Long Sleeve Top",
    "Short Sleeve Outwear",
    "Long Sleeve Outwear",
    "Vest",
    "Sling"
]

const bottoms = [
    "Shorts",
    "Trousers",
    "Skirt",
    "Short Sleeve Dress",
    "Long Sleeve Dress",
    "Vest Dress",
    "Sling Dress"
]

const outfit_combinations = [
    ["Short Sleeve Top", "Shorts"],
    ["Short Sleeve Top", "Trousers"],
    ["Short Sleeve Top", "Skirt"],
    ["Short Sleeve Dress"],
    ["Long Sleeve Top", "Shorts"],
    ["Long Sleeve Top", "Trousers"],
    ["Long Sleeve Top", "Skirt"],
    ["Long Sleeve Dress"],
    ["Short Sleeve Outwear", "Short Sleeve Top", "Shorts"],
    ["Short Sleeve Outwear", "Short Sleeve Top", "Trousers"],
    ["Short Sleeve Outwear", "Short Sleeve Top", "Skirt"],
    ["Short Sleeve Outwear", "Short Sleeve Dress"],
    ["Long Sleeve Outwear", "Long Sleeve Top", "Shorts"],
    ["Long Sleeve Outwear", "Long Sleeve Top", "Trousers"],
    ["Long Sleeve Outwear", "Long Sleeve Top", "Skirt"],
    ["Long Sleeve Outwear", "Long Sleeve Dress"],
]

const pages = ["Closet", "Review", "Account", "Today"];
const page_tooltips = [
    "Your closet",
    "Review outfits to get better recommendations",
    "Your account",
    "Your outfits for today"
]

const affiliates = ["newchic"]

const code_size = 256;
const img_size = 128;

const max_articles = 5;

export { categories, code_size, tops, bottoms, max_articles, pages, outfit_combinations, img_size, affiliates, page_tooltips}