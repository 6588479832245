//Style sheets
import "./App.css";
import {createTheme, ThemeProvider} from '@mui/material/styles';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, collection, getDocs, addDoc, query, where, doc, updateDoc, deleteDoc } from "firebase/firestore";

import { useEffect, useState } from "react";
import LoginPage from "./Login";
import ClosetPage from "./Closet";
import ReviewPage from "./Review";
import Navbar from "./Navbar";
import { Box } from "@mui/system";

//React Router
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import AddArticlePage from "./AddArticle";
import DetailsPage from "./Details";
import AccountPage from "./Account";
import PrivacyPage from "./Privacy";
import LandingPage from "./Landing";
import RecommendationPage from "./Recommendation";
import { code_size } from "./Config";




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_qpaJ32CIlCwSOvRojyGKLklkALg4bQI",
  authDomain: "lylaloom.firebaseapp.com",
  projectId: "lylaloom",
  storageBucket: "lylaloom.appspot.com",
  messagingSenderId: "772303413855",
  appId: "1:772303413855:web:b2fcad017dbfc0197531ea",
  measurementId: "G-C7PS0080V0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const auth = getAuth();
  
  
  const theme = createTheme({
    palette: {
        primary: {
            main: "#3f50b5",
        },
        secondary: {
            main: "#f44336",
            light: "#ff7961",
        },
    },
    typography: {
        fontFamily: "Roboto",
    }
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setUser(user);

        // Get the user data from the database
        const db = getFirestore();
        const q = query(collection(db, "users"), where("uid", "==", uid));
        getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((user_doc) => {
            let u_data = user_doc.data();
            

            if(user_doc.data().avgLikedOutfit === undefined) {
              // If the user doesn't have an average liked outfit, then set it to the 0 vector (1xcode_size)
              updateDoc(doc(db, "users", user_doc.id), {
                avgLikedOutfit: Array(code_size).fill(0)
              })
              u_data.avgLikedOutfit = Array(code_size).fill(0);
            }
            setUserData(u_data);
          });
        });
      } else {
        // User is signed out
        setUser(null);
      }
    });
  }, []);
  
  const pageWrapper = (page) => {
    // This is a wrapper for the pages that will be displayed, so that the navbar is always on the left
    // and the page is always on the right without overlap
    let navbarWidth = "110px";
    let pageWidth = `calc(100% - ${navbarWidth})`;

    return (
      <Box sx={{width: pageWidth, height: "100%", position: "absolute", top: 0, left: "110px"}}>
        {page}
      </Box>
    )
  }

  const addArticleToUser = (article) => {
    // Add an article to the local copy of the user so that we don't have to refetch the user data
    // from the database
    let newUserData = userData;
    newUserData.closet.push(article.docId)
    newUserData.closet_images.push(article.photoURL)
    console.log(newUserData)
    setUserData(newUserData);
  }

  const removeArticleFromUser = (articleId) => {
    // Remove an article from the local copy of the user so that we don't have to refetch the user data
    // from the database
    let newUserData = userData;
    let index = newUserData.closet.indexOf(articleId);
    newUserData.closet.splice(index, 1);
    newUserData.closet_images.splice(index, 1);
    setUserData(newUserData);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{width: "100%", height: "100%"}}>
        {
          user ? (
            <>
              <Box sx={{width: "100%", height: "100%"}}>
                <Router>
                  <Box sx={{width:"100px", height: "100%"}}>
                    <Navbar user={userData}/>
                  </Box>
                  <Routes>
                    <Route path="/" element={
                      pageWrapper(<ClosetPage user={userData}/>)
                    }/>
                    <Route path="/closet" element={
                      pageWrapper(<ClosetPage user={userData}/>)
                    }/>
                    <Route path="/review" element={
                      pageWrapper(<ReviewPage user={userData}/>)
                      }/>
                    <Route path="/add" element={
                      pageWrapper(<AddArticlePage user={userData} 
                                  addArticleToUser={addArticleToUser}/>)
                    }/>
                    <Route path="/details/:id" element={
                      pageWrapper(<DetailsPage user={userData} 
                                  removeArticleFromUser={removeArticleFromUser}/>)
                    }/>
                    <Route path="/account" element={
                      pageWrapper(<AccountPage user={userData} auth={auth}/>)
                    }/>
                    <Route path="/privacy" element={
                      pageWrapper(<PrivacyPage/>)
                    }/>
                    <Route path="/today" element={
                      pageWrapper(<RecommendationPage user={userData}/>)
                    }/>
                  </Routes>
                </Router>
              </Box>
            </>
          )
          : (
            <Router>
              <Routes>
                <Route path="/" exact element={
                  pageWrapper(<LandingPage/>)
                }/>
                <Route path="/privacy" exact element={
                  pageWrapper(<PrivacyPage/>)
                }/>
                <Route path="*" element={
                <Box sx={{
                  width: "100%", height: "100vh",
                  display: "flex", flexDirection: "column", 
                  alignItems: "center", justifyContent: "center"
                }}>
                  <Box sx={{display: "flex", flexDirection: "column", 
                        width: {xs: "100%", sm: "700px"}, height: "400px"}}>
                    <LoginPage/>
                  </Box>
                </Box>}/>
              </Routes>
            </Router>
          )
        }
      </Box>
    </ThemeProvider>
  );
}

export default App;
