import { Box, Button, MenuItem, Modal, Select, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import {getFirestore, collection, getDocs, doc, getDoc, updateDoc} from 'firebase/firestore/lite';
import {categories, affiliates} from "./Config";

function DetailsPage(props) {

    const {id} = useParams();
    const [articleData, setArticleData] = React.useState(null);
    const [localCategory, setLocalCategory] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const db = getFirestore();
        const articleRef = doc(db, "articles", id);
        getDoc(articleRef).then((data) => {
            if (data.exists()) {
                let article_data = data.data();

                //Check if category is a number, if it is parse string to int
                if(!isNaN(article_data.category))
                {
                    article_data.category = categories[parseInt(article_data.category)];
                }

                setArticleData(article_data);
                setLocalCategory(article_data.category);
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }, [id])

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
        }}
        >
            <Modal open={open} onClose={() => setOpen(false)} sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "400px",
                    height: "200px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    p: 2,
                    margin: "auto",

                }}>
                    <Typography variant="h4" sx={{textAlign:"center"}}>
                        Are you sure you want to delete this article?
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                        mt: 2,

                    }}>
                        <Button variant="outlined" onClick={() => {
                        //Delete article from user's closet
                        const db = getFirestore();
                        const userRef = doc(db, "users", props.user.uid);
                        getDoc(userRef).then((data) => {
                            if (data.exists()) {
                                const user = data.data();
                                const closet = user.closet;
                                const closet_images = user.closet_images;
                                const index = closet.indexOf(id);
                                closet.splice(index, 1);
                                closet_images.splice(index, 1);
                                updateDoc(userRef, {
                                    closet: closet,
                                    closet_images: closet_images,
                                });
                            }

                            //Delete article from database
                            const articleRef = doc(db, "articles", id);
                            updateDoc(articleRef, {
                                owner: "",
                            });

                            props.removeArticleFromUser(id);
                            //Redirect to closet
                            navigate("/closet");
                        }).catch((error) => {
                            console.log("Error getting document:", error);
                        });
                        }}>Yes</Button>
                        <Button variant="outlined" onClick={() => setOpen(false)}>
                            No
                        </Button>
                    </Box>

                </Box>
            </Modal>
            
            {articleData && (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: {xs: "100%", sm: "400px"},
            }}>
                <img src={articleData.photoURL} alt="article" 
                     style={{width: "300px", height: "auto", margin: "auto"}}/>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    mt: 3,
                }}>
                    <Typography variant="h6" htmlFor="category-select">
                        Category
                    </Typography>
                    <Select value={localCategory} 
                    id="category-select"
                    onChange={(e) => {
                        setLocalCategory(e.target.value);
                        const db = getFirestore();
                        const articleRef = doc(db, "articles", id);
                        updateDoc(articleRef, {
                            category: e.target.value,
                        });
                        console.log("Updated category to " + e.target.value);
                    }}>
                        {categories.map((category) => {
                            return (
                                <MenuItem value={category} key={category}>
                                    {category}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Box>
                { affiliates.includes(articleData.origin) && (
                <>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                }}>
                    <Typography variant="h6" htmlFor="category-select">
                        Origin
                    </Typography>
                    <Typography variant="body1">
                        {articleData.origin}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",

                }}>
                    <Button component={Link} to={articleData.url} variant="outlined" sx={{
                        mt: 2,
                        color: "primary.main",
                        borderColor: "primary.main",
                        '&:hover': {
                            backgroundColor: "primary.main",
                            color: "white",
                            borderColor: "primary.main",
                        }
                    }}>Buy For ${articleData.price}</Button>
                </Box>
                </>)}
                { !affiliates.includes(articleData.origin) && (
                <Button variant="outlined" sx={{
                    mt: 2,
                    color: "secondary.main",
                    borderColor: "secondary.main",
                    '&:hover': {
                        backgroundColor: "secondary.main",
                        color: "white",
                        borderColor: "secondary.main",
                    }
                }} 
                onClick={
                    () => setOpen(true)
                }>Delete From Closet</Button>)}
            </Box>
            )}

            
        </Box>
    );
}

export default DetailsPage;