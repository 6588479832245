import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function LandingPage(props) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
        }}>
            <Typography variant="h1" sx={{
                textAlign: "center",
            }}>
                Lyla Loom
            </Typography>
            <Typography variant="h5" sx={{
                textAlign: "center",
            }}>
                Your Personalized Fashion Assistant
            </Typography>
            <Box sx={{height: "100px"}}></Box>
            
            <Button to={"/login"} component={Link} variant="contained">
                <Typography variant="h5" sx={{
                    textAlign: "center",
                    cursor: "pointer",
                }}>
                    Set up your Account
                </Typography>
            </Button>
        </Box>
    );
}

export default LandingPage;