import React, {useEffect, useState} from 'react';
import { Box } from "@mui/system";
import { getFirestore, collection, addDoc} from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Button, CircularProgress, MenuItem, Paper, Select, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {categories, img_size} from "./Config";

function AddArticlePage(props) {
    const [photo, setPhoto] = useState(null);
    const [category, setCategory] = useState("Short Sleeve Top");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [embedding, setEmbedding] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (photo) {
            getEmbedding(photo).then((embedding) => {
                setEmbedding(embedding);
            });
        }
    }, [photo]);

    const argMax = (array) => {
        let max = array[0];
        let maxIndex = 0;
        for (let i = 1; i < array.length; i++) {
            if (array[i] > max) {
                maxIndex = i;
                max = array[i];
            }
        }
        return maxIndex;
    }

    useEffect(() => {
        if (embedding) {
            fetch("https://us-central1-lylaloom.cloudfunctions.net/article_classification", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    embedding: embedding,
                }),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                let argmax_predicted = argMax(data);
                console.log(data);
                setCategory(categories[argmax_predicted]);
            });
        }
    }, [embedding]);

    const resizeImage = (photo, width, height=null) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(photo);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const elem = document.createElement("canvas");
                    elem.width = width;
                    if (height) {
                        elem.height = height;
                    } else {
                        elem.height = width * img.height / img.width;
                    }
                    const ctx = elem.getContext("2d");
                    ctx.drawImage(img, 0, 0, elem.width, elem.height);
                    ctx.canvas.toBlob((blob) => {
                        const file = new File([blob], photo.name, {
                            type: "image/jpeg",
                            lastModified: Date.now()
                        });
                        resolve(file);
                    }, "image/jpeg", 1);
                };
                reader.onerror = error => reject(error);
            };
        });
    }

    const getEmbedding = async (photo) => {
        let resizedPhoto = await resizeImage(photo, img_size, img_size);

        //Normalize resized photo and store as img_sizeximg_sizex3 array
        const reader = new FileReader();
        reader.readAsDataURL(resizedPhoto);
        const normalized = await new Promise((resolve, reject) => {
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = img_size;
                    canvas.height = img_size;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, img_size, img_size);
                    const data = ctx.getImageData(0, 0, img_size, img_size).data;
                    //Get the data, normalize it, and store it in a img_sizeximg_sizex3 array
                    let normalized = [];
                    for (let i = 0; i < img_size; i++) {
                        let row = [];
                        for (let j = 0; j < img_size; j++) {
                            let pixel = [];
                            for (let k = 0; k < 3; k++) {
                                pixel.push(data[(i * img_size + j) * 4 + k] / 255);
                            }
                            row.push(pixel);
                        }
                        normalized.push(row);
                    }

                    resolve(normalized);
                }
            }
        });

        //Create JSON input
        const input = {
            image: normalized
        }

        //Make prediction
        const url = "https://us-central1-lylaloom.cloudfunctions.net/embed_image";
        const embedding = await fetch(url, {
            method: "POST",
            headers: {                
                "Content-Type": "application/json",
            },
            body: JSON.stringify(input)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            return data;
        }).catch((error) => {
            console.log(error);
        });

        return embedding;
    }

    const uploadPhoto = async () => {
        if (embedding === null) {
            console.log("No embedding");
            return;
        }
        //Upload image
        const storage = getStorage();
        const resizedPhoto = await resizeImage(photo, 300);
        const sRef = storageRef(storage, `images/${photo.name}`);
        const uploadTask = uploadBytesResumable(sRef, resizedPhoto);
        uploadTask.on("state_changed", (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
            setUploadProgress(progress);
            switch (snapshot.state) {
                case "paused":
                    console.log("Upload is paused");
                    break;
                case "running":
                    console.log("Upload is running");
                    break;
            }
        }, (error) => {
            console.log(error);
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log("File available at", downloadURL);
                console.log("Embedding", embedding);
                addArticle(downloadURL, embedding, 2);

                //Go to closet
                navigate("/closet");
            });
        });
    }

    const addArticle = async (photoURL, embedding, embeddingVersion) => {
        const db = getFirestore();
        const articleRef = await addDoc(collection(db, "articles"), {
            category: category,
            photoURL: photoURL,
            origin: props.user.uid,
            embedding: embedding,
            embeddingVersion: embeddingVersion,
        });

        let categoryIndex = categories.indexOf(category);

        //Add article to user's closet
        console.log("Adding article to user's closet")
        const userRef = doc(db, "users", props.user.uid);
        const userDoc = await getDoc(userRef);
        const user = userDoc.data();
        const closet = user.closet;
        closet.push(articleRef.id);
        const closet_images = user.closet_images;
        closet_images.push(photoURL);
        if (user["category_count_" + categoryIndex] === undefined) {
            user["category_count_" + categoryIndex] = 0;
        }
        const category_count = user["category_count_" + categoryIndex] + 1;
        const newDoc = {
            closet: closet,
            closet_images: closet_images,
        }
        newDoc["category_count_" + categoryIndex] = category_count;
        await updateDoc(userRef, newDoc);

        console.log("Document written with ID: ", articleRef.id);
        props.addArticleToUser(
            {
                docId: articleRef.id,
                photoURL: photoURL,
                category: category,
            }
        )
    }

    return (
        <Box sx={{width: "100%", 
                  height: "100%", 
                  display: "flex", 
                  flexDirection: "column", 
                  alignItems: "center", justifyContent: "center", p: 1}}>
            <Snackbar open={uploadProgress > 0 && uploadProgress < 100} 
                      message={`Uploading... ${uploadProgress}%`} />
            <Paper elevation={2}>
                <Box sx={{ width: {xs: "100%", sm: "700px"}, margin: "auto", 
                           display: "flex", flexDirection: "column", 
                           alignItems: "center", p:2}}>
                    {/* Photo upload */}
                    {photo ? (
                        <>
                            <img src={URL.createObjectURL(photo)} alt="preview" 
                                 style={{width: "300px", maxHeight: "500px"}} />
                            <Button onClick={() => {
                                setPhoto(null);
                            }}>Remove</Button>
                        </>
                    )
                    :
                    <>
                        <input
                            accept="image/*"
                            hidden
                            type="file"
                            id="file"
                            onChange={(e) => {
                                setPhoto(e.target.files[0]);
                            }}
                        />
                        <label htmlFor="file">
                            <Box sx={{
                                width: "300px",
                                height: "300px",
                                backgroundColor: "#e0e0e0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: "#c0c0c0",
                                }
                            }}
                            >
                                <p>Add a photo or drag</p>

                                <AddToPhotosIcon />
                            </Box>
                        </label>
                    </>
                    }
                    

                </Box>
                { photo && (
                <>
                    {/* Metadata */}
                    <Box sx={{ width: {xs: "100%", sm: "700px"}, margin: "auto", 
                               display: "flex", flexDirection: "column", 
                               alignItems: "center", p:2}}>
                        <Select value={category} onChange={(e) => {
                            setCategory(e.target.value);
                        }}
                            sx={{width: "300px"}}
                        >
                            {categories.map((category) => {
                                return (
                                    <MenuItem value={category} key={category}>
                                        {category}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Box>

                    {/* Submit */}
                    {embedding === null ? 
                    <Box sx={{ width: {xs: "100%", sm: "700px"}, margin: "auto", 
                    display: "flex", flexDirection: "column", 
                    alignItems: "center", p:2}}>
                        <CircularProgress /> 
                    </Box>:
                    <Box sx={{ width: {xs: "100%", sm: "700px"}, margin: "auto", 
                               display: "flex", flexDirection: "column", 
                               alignItems: "center", p:2}}>
                        <Button onClick={() => {
                            uploadPhoto();
                        }}
                        variant="contained"
                        >Submit</Button>
                    </Box>}
                </>
                )}

            </Paper>
        </Box>
    );
}

export default AddArticlePage;