import React, { useEffect, useState } from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {code_size, tops, bottoms, max_articles, outfit_combinations} from "./Config";
import {getFirestore, doc, getDoc, addDoc, collection, updateDoc} from 'firebase/firestore/lite';
import { Box } from '@mui/system';
import { Button, Tooltip, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ReviewPage(props) {

    const [closetItems, setClosetItems] = useState({});
    const [allItems, setAllItems] = useState([]);
    const [outfits, setOutfits] = useState([]);
    const [curOutfit, setCurOutfit] = useState(0);
    const [generating, setGenerating] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if(props.user === null) {
            return;
        }
        setGenerating(true);

        getClosetItems().then((closet) => {
            setAllItems(closet);
            let categoryCloset = {}
            for (let i = 0; i < closet.length; i++) {
                if (closet[i].category in categoryCloset) {
                    categoryCloset[closet[i].category].push(closet[i]);
                } else {
                    categoryCloset[closet[i].category] = [closet[i]];
                }
            }

            //Iterate over keys
            for (let key in categoryCloset) {
                let category = categoryCloset[key];
                for (let i = 0; i < category.length; i++) {
                    categoryCloset[key][i].similar = 
                        getSimilarArticle(category[i].embedding, 
                                        category, 
                                        5, 
                                        [category[i].id]); 
                }
            }
            if (Object.keys(categoryCloset).length === 0) {
                setGenerating(false);
            }
            setClosetItems(categoryCloset);
        });
    }, [props.user])

    useEffect(() =>{
        if (Object.keys(closetItems).length === 0) {
            return;
        }

        for (let i = 0;i < 5;i++)
        {
            generateOutfit().then((outfit) => {
                if (outfit.length == 0) {
                    setGenerating(false);
                    setOutfits([]);
                    return;
                }
                setOutfits((prev) => [...prev, outfit]);
                setGenerating(false);
            });
        }

    }, [closetItems])

    const getClosetItems = async () => {
        if (props.user === null) {
            return [];
        }
        let closetItems = props.user.closet;

        //Get items from articles db
        const db = getFirestore();
        let closetItemsData = [];
        
        //Start requests for all articles and await them
        let promises = [];
        for (let i = 0; i < closetItems.length; i++) {
            promises.push(getDoc(doc(db, "articles", closetItems[i])));
        }
        let results = await Promise.all(promises);

        //Get data from results
        for (let i = 0; i < results.length; i++) {
            let data = results[i].data();
            data.id = results[i].id;
            closetItemsData.push(data);
        }
        return closetItemsData;
    }

    const getSimilarArticle = (embedding, searchSpace, topn, blacklist = []) => {
        //Sort by cosine similarity to embedding and return topn
        let similarities = [];
        for (let i = 0; i < searchSpace.length; i++) {
            if (!blacklist.includes(searchSpace[i].id)) {
                similarities.push({
                    id: searchSpace[i].id,
                    similarity: cosineSimilarity(embedding, searchSpace[i].embedding)
                });
            }
        }

        similarities.sort((a, b) => b.similarity - a.similarity);
        return similarities.slice(0, topn);
    }

    const cosineSimilarity = (a, b) => {
        let dotProduct = 0;
        let aMagnitude = 0;
        let bMagnitude = 0;
        for (let i = 0; i < a.length; i++) {
            dotProduct += a[i] * b[i];
            aMagnitude += a[i] * a[i];
            bMagnitude += b[i] * b[i];
        }
        aMagnitude = Math.sqrt(aMagnitude);
        bMagnitude = Math.sqrt(bMagnitude);
        return dotProduct / (aMagnitude * bMagnitude);
    }

    const randomValidOutfit = () => {
        let valid_list = [];
        for (let i = 0; i < outfit_combinations.length; i++) {
            //If the user has at least one item in each category
            if (outfit_combinations[i].every((category) => {
                return category in closetItems && closetItems[category].length > 0;
            })) {
                valid_list.push(outfit_combinations[i]);
            }
        }
        if (valid_list.length === 0) {
            return [];
        }
        let randomIndex = Math.floor(Math.random() * valid_list.length);
        return valid_list[randomIndex];
    }

    const getOutfitPrediction = async (articles) => {
        let embeddings = getOutfitEmbedding(articles, props.user.avgLikedOutfit);

        const input = {
            outfit: embeddings
        }
        const url = "https://us-central1-lylaloom.cloudfunctions.net/fashion_prediction"
        const result = await fetch(url, {
            method: "POST",
            headers: {                
                "Content-Type": "application/json",
            },
            body: JSON.stringify(input)
        }).then((response) => {
            return response.json();
        }).then((data) => {
            return data;
        }).catch((error) => {
            console.log(error);
        });

        return result;
    }

    const getOutfitEmbedding = (articles, user_embedding) => {
        if (user_embedding.length > code_size) {
            user_embedding = user_embedding.slice(0, code_size);
        }
        let embedding = user_embedding;
        for (let i = 0; i < articles.length; i++) {
            embedding = embedding.concat(articles[i].embedding);
        }

        //Pad with zeros if there are less than 5 articles
        if (embedding.length < (max_articles)*code_size) {
            let padLength = ((max_articles) - (embedding.length/code_size)) * code_size;
            for (let i = 0; i < padLength; i++) {
                embedding.push(0);
            }
        }
        return embedding;
    }

    const submitReview = async (outfit, rating) => {
        setCurOutfit(curOutfit + 1);
        //Start generating new outfit
        generateOutfit().then((outfit) => {
            setOutfits(outfits => [...outfits, outfit]);
        });

        let outfit_embedding = getOutfitEmbedding(outfit, props.user.avgLikedOutfit);

        //Add review to db
        const db = getFirestore();
        //Get timestamp as string
        let timestamp = new Date().toISOString();
        const docRef = await addDoc(collection(db, "reviews"), {
            user: props.user.uid,
            outfit: outfit_embedding,
            rating: rating,
            articles: outfit.map((article) => article.id),
            timestamp: timestamp,
        });
        if (rating == 1) {
            //Update the user's average liked outfit
            const userRef = doc(db, "users", props.user.uid);
            const userDoc = await getDoc(userRef);
            const user = userDoc.data();
            console.log(user)
            let newAvg = [];
            let reviewCount = user.review_count;
            if (user.avgLikedOutfit == undefined) {
                user.avgLikedOutfit = new Array(max_articles*code_size).fill(0);
            }
            for (let i = 0; i < outfit_embedding.length; i++) {
                newAvg.push((user.avgLikedOutfit[i] * reviewCount + outfit_embedding[i]) / (reviewCount + 1));
            }
            reviewCount += 1;
            await updateDoc(userRef, {
                avgLikedOutfit: newAvg,
                review_count: reviewCount,
            });
        }
    }

    const randomOutfit = (categories) => {
        let outfit = [];
        for (let i = 0; i < categories.length; i++) {
            let category = categories[i];
            let randomIndex = Math.floor(Math.random() * closetItems[category].length);
            outfit.push(closetItems[category][randomIndex]);
        }
        return outfit;
    }

    const generateOutfit = async (iterations, temperatue, earlyTerminateThreshold) => {
        let validOutfit = randomValidOutfit();
        if (validOutfit.length === 0) {
            return [];
        }
        let outfit = randomOutfit(validOutfit);
        let curScore = await getOutfitPrediction(outfit);
        //Simulated annealing
        for (let i = 0;i<iterations;i++) {
            let outfitCopy = outfit.slice();

            //Pick random article to replace
            let randomIndex = Math.floor(Math.random() * outfitCopy.length);
            
            //Pick random article from similar list to replace with
            let similarArticles = closetItems[validOutfit[randomIndex]][randomIndex].similar;
            let randomSimilarIndex = Math.floor(Math.random() * similarArticles.length);
            let randomSimilarArticleID = similarArticles[randomSimilarIndex];

            let randomSimilarArticle = allItems.find((article) => article.id == randomSimilarArticleID.id);
            //Replace article
            outfitCopy[randomIndex] = randomSimilarArticle;

            //Get outfit prediction
            let outfitPrediction = await getOutfitPrediction(outfitCopy);
            if (outfitPrediction.error) {
                console.log(outfitPrediction.error);
                return outfit;
            }
            let outfitPredictionScore = outfitPrediction.predictions[0][0];

            //Calculate probability of accepting worse solution
            let probAccept = Math.exp((outfitPredictionScore - curScore) / temperatue);

            //Accept worse solution with probability probAccept
            if (outfitPredictionScore > curScore || Math.random() < probAccept) {
                outfit = outfitCopy;
                curScore = outfitPredictionScore;
                
            } else if (outfitPredictionScore > earlyTerminateThreshold) {
                outfit = outfitCopy;
                break;
            }
        }
        return outfit;
    }

    return (
        <Box sx={{width: "100%", height: "100%", overflowY: "auto", overflowX: "hidden",
                  display: "flex", flexDirection: "column", alignItems: "center", 
                  justifyContent: "center"}}>
            {/* Outfit Display Box */}
            {outfits.length > 0 ?
            <>
            {outfits[curOutfit] && 
            <Box sx={{width: {xs: "100%", sm: "400px", md: "600px", lg: "700px"},
                      display: "flex", flexDirection: "column", mb: 2}}>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", 
                          justifyContent: "center"}}>
                    {outfits[curOutfit].map((article) => {
                        return (<div key={article.id}>{tops.includes(article.category) ? 
                            <Tooltip title={article.category}>
                                <img src={article.photoURL} style={{height: "300px"}}/>
                            </Tooltip> : null
                        }</div>)
                    })}
                </Box>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center",
                          justifyContent: "center", mt: 1}}>
                    {outfits[curOutfit].map((article) => {
                        return (<div key={article.id}>
                        {bottoms.includes(article.category) ? 
                        <Tooltip title={article.category}>
                            <img src={article.photoURL} style={{height: "300px"}}/>
                        </Tooltip> : null
                        }</div>)
                    })}
                </Box>
                
            </Box>}

            {/* Outfit Rating Buttons */}
            <Box sx={{width: {xs: "100%", sm: "700px"},
                        display: "flex", flexDirection: "row", alignItems: "center",
                        justifyContent: "space-between"}}>
                <Button variant="contained" onClick={() => 
                    submitReview(outfits[curOutfit], 1)}>
                        Like
                </Button>
                <Button variant="contained" onClick={() =>
                    submitReview(outfits[curOutfit], 0)}>
                        Dislike
                </Button>
            </Box>
            </> :
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
            }}>
                {generating ? 
                <CircularProgress /> :
                <>
                    <Typography variant="h4" sx={{mb: 2}}>
                        You don't have enough items in your closet to generate outfits.
                    </Typography>
                    <Button variant="contained" onClick={() => navigate("/closet")}>
                        Go to Closet
                    </Button>
                </>}
            </Box>
            }

        </Box>
    );
}

export default ReviewPage;