import React, {useEffect, useState} from 'react';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, collection, getDocs, addDoc, query, where, doc, updateDoc, deleteDoc, setDoc, getDoc } from "firebase/firestore";
import Box from '@mui/material/Box';
import { Button, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { max_articles, code_size, categories } from './Config';
import { useNavigate } from 'react-router-dom';

function LoginPage(props) {
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [error, setError] = useState(null);

    const theme = useTheme();
    const navigate = useNavigate();

    const createDefaultUser = (uid) => {
        // Create a user in the database
        const db = getFirestore();
        const userInfo = {
            displayName: displayName,
            email: email,
            uid: uid,
            closet: [],
            closet_images: [],
            review_count: 0,
            avgLikedOutfit: new Array(max_articles*code_size).fill(0),
        }

        //Append category counts
        for (let i = 0; i < categories.length; i++) {
            userInfo["category_count_" + i] = 0;
        }

        setDoc(doc(db, "users", uid), userInfo)
    }

    const login = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            createDefaultUser(user.uid);
            navigate("/closet");
            
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorMessage);
        });
    }

    const createAccount = () => {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            createDefaultUser(user.uid);
            navigate("/closet");
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorMessage);
        });
    }

    const googleLogin = () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            
            // If the user is new, create a user in the database
            const db = getFirestore();
            const userRef = doc(db, "users", user.uid);
            
            getDoc(userRef).then((data) => {
                
                if (!data.exists()) {
                    createDefaultUser(user.uid);
                }
                navigate("/closet");
            }).catch((error) => {
                console.log("Error getting document:", error);
            });

        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", columnGap: "10px", rowGap: "10px"}}>
            {isLogin ?
            <>
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <TextField label="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>

                <Typography variant="body1" color="error">{error}</Typography>

                {/* Google Login */}
                <Button variant="outlined" onClick={googleLogin} sx={{color: "black"}}>
                    <img width="20px" style={{marginTop: "0px", marginRight: "8px"}} alt="Google sign-in" 
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                    Login with Google
                </Button>

                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Button variant="contained" onClick={login}>Login</Button>
                    <Button variant="outlined" onClick={() => setIsLogin(false)}>Create Account</Button>

                </Box>
            </>
            :
            <>
                <TextField label="Display Name" value={displayName} onChange={(e) => setDisplayName(e.target.value)}/>
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <TextField label="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>

                <Typography variant="body1" color="error">{error}</Typography>

                {/* Google Login */}
                <Button variant="outlined" onClick={googleLogin} sx={{color: "black"}}>
                    <img width="20px" style={{marginTop: "0px", marginRight: "8px"}} alt="Google sign-in" 
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                    Login with Google
                </Button>

                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Button variant="contained" onClick={createAccount}>Create Account</Button>
                    <Button variant="outlined" onClick={() => setIsLogin(true)}>Login</Button>
                </Box>
            </>
            }
        </Box>
    );
}

export default LoginPage;