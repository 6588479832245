import React from 'react';
import { Box } from '@mui/system';
import { Button, Dialog, Modal, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { code_size, max_articles } from './Config';
import { getFirestore, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getAuth, deleteUser, signOut } from 'firebase/auth';


function AccountPage(props) {

    const [open, setOpen] = React.useState(false);

    const [confirmationMessage, setConfirmationMessage] = React.useState("");
    const [confirmationAction, setConfirmationAction] = React.useState(0);
    const navigate = useNavigate();

    const resetUserOutfitProfile = (confirmed) => {
        if (confirmed !== true)
            return;
        console.log("Resetting user outfit profile...")
        //Set avgLikedOutfit back to 0 vector
        const db = getFirestore();
        const userRef = doc(db, "users", props.user.uid);
        updateDoc(userRef, {
            avgLikedOutfit: new Array(max_articles * code_size).fill(0),
            review_count: 0,
        })
        props.user.avgLikedOutfit = new Array(max_articles * code_size).fill(0);
    }

    const deleteAccount = (confirmed) => {
        if (confirmed !== true)
            return;

        //Delete user from auth
        const auth = props.auth
        deleteUser(auth.currentUser).then(() => {
            //Delete user from database
            const db = getFirestore();
            const userRef = doc(db, "users", props.user.uid);
            deleteDoc(userRef).then(() => {
                //Sign out
                signOut(auth).then(() => {
                    navigate("/");
                })
            })
        })
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
        }}
        >
            <Dialog open={open} onClose={() => setOpen(false)} sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "400px",
                    height: "200px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    p: 2,
                    margin: "auto",

                }}>
                    <Typography variant="h5" sx={{
                        textAlign: "center",
                    }}>
                        {confirmationMessage}
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                        mt: 3,
                    }}>
                        <Button variant="outlined" onClick={() => {
                            if (confirmationAction === 1)
                            {
                                resetUserOutfitProfile(true);
                            }
                            else if (confirmationAction === 2)
                            {
                                deleteAccount(true);
                            }
                            setOpen(false);
                        }}>Yes</Button>
                        <Button variant="outlined" onClick={() => {
                            setOpen(false);
                        }}>No</Button>
                    </Box>
                </Box>
            </Dialog> 

            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: {xs: "100%", sm: "400px"},
            }}>
                <Typography variant="h5">Settings</Typography>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <Typography variant="h4"></Typography>
                </Box>
                <Typography variant="h5">Account</Typography>
                <Button variant="outlined" onClick={() => {
                    setOpen(true);
                    setConfirmationMessage("Are you sure you want to reset your outfit profile?");
                    setConfirmationAction(1);
                }}>Reset Outfit Profile</Button>

                <Button variant="outlined" color="error" 
                sx={{
                    mt: 2,
                }}
                onClick={() => {
                    navigate("/");
                    props.auth.signOut();
                }}>Sign Out</Button>

                <Button variant="outlined" color="error"
                sx={{
                    mt: 2,
                }}
                onClick={() => {
                    setOpen(true);
                    setConfirmationMessage("Are you sure you want to delete your account? This action is non reversible.");
                    setConfirmationAction(2);
                }}>Delete Account</Button>

                <Typography variant="h5" sx={{mt: 2}}>Privacy</Typography>
                <p>
                    Learn about information we store 
                    <Link to="/privacy" 
                    style={{textDecoration: "none", 
                            color: "black", 
                            fontSize: "20px", 
                            margin: "10px"}} 
                            key="privacy">
                        here
                    </Link>
                </p>

                


            </Box>
        </Box>
    );
}

export default AccountPage;